import styled from 'styled-components';
export const Body = styled.div`
  margin: 0 auto;
  text-align: center;
  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
    height: auto;
  }
`;

export const ParaText = styled.p`
  padding: 0;
  margin: 0;
  font-size: 18px;
  text-align: center;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #00539f;
  display: block;
  font-family: 'Helvetica-Condensed', arial, sans-serif;
  b {
    font-family: 'MrAlex', arial, sans-serif;
    font-weight: bold;
    margin-bottom: 5px;
  }
  a > code {
    text-decoration: none;
    color: #ffca05;
    transition: color 0.15s ease-out;
    font-family: 'Helvetica-Condensed', arial, sans-serif;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: 0.02em;
    :hover {
      color: #00539f;
    }
  }
`;
